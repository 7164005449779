.LoginPageHolder {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    box-sizing: border-box;
    justify-content: center;
    z-index: 2;
    position: fixed;
    width: 100%;
    cursor: url(drone-cursor.png) 48 30, pointer;
}
/* 
* {
    cursor: url(drone-cursor.png) 200 200, pointer;

} */

.Logo {
    max-width: 200px;
    margin: 0 0 50px 0;
    image-rendering: crisp-edges;
    image-rendering: pixelated;
    image-rendering: -webkit-optimize-contrast;
}

.LoginButton {
    font-size: 14px;
    font-family: 'Fiery Turk';
    padding: 10px 15px;
    background: white;
    border: 2px solid black;
    font-smooth: never;
    -webkit-font-smoothing : none;
    /* cursor: pointer; */
    /* cursor: url(drone-cursor.png), pointer; */
    cursor: inherit;
    transition: all 0.1s;
}

.LoginButton:hover {
    background: black;
    color: white;
    transform: scale(1.1);
}