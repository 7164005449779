

.AdminPanel {
    position: absolute;
    z-index: 99;
    background: white;
    bottom: 0;
    width: 100vw;
    height: 220px;
}

.AdminPanel__Content {
    position: relative;
    display: flex;
    overflow-x: scroll;
    height: 100%;
}

.AdminPanel__Content > p {
    width: 100%;
    text-align: center;
    height: 100%;
}

.ImageBlock, .ImageBlockUsage {
    padding: 15px;
    height: 200px;
    width: 200px;
    box-sizing: border-box;
    border: 2px solid black;
    padding: 2px;
    margin: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ImageBlock > img, .ImageBlockUsage > img {
    width: 100%;
    height: 100%;
}

.ImageBlockUsage > img {
    opacity: 0.5;
}

.ImageButton {
    position: absolute;
    width: 80px;
    border: 2px solid black;
    background: white;
    font-family: 'Fiery Turk';
    font-size: 12px;
    height: 40px;
    cursor: pointer;
    font-smooth: never;
    -webkit-font-smoothing : none;
    transition: all 0.1s;
    opacity: 0;
}


.ImageBlockUsage:hover > .ImageButton {
    opacity: 1;
}

.ImageButton:hover {
    background: black;
    color: white;
}