
@font-face {
  font-family: 'Fiery Turk';
  src: url(fonts/Fiery_Turk.ttf);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-family: 'Fiery Turk';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}



.p5Canvas {
  position: fixed;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
}

.uipanel__full {
  position: fixed;
  display: flex;
  flex-direction: row;
  top: 0;
  height: 100vh;
  z-index: 999;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: white;
}

.uipanel__top {
  position: fixed;
  display: flex;
  flex-direction: row;
  top: 10px;
  z-index: 999;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: white;
  font-size: 16px;
  user-select: none;
  cursor: none;
  text-shadow: 2px 0 0 #000, -2px 0 0 #000, 0 2px 0 #000, 0 -2px 0 #000, 2px 2px #000, -2px -2px 0 #000, 2px -2px 0 #000, -2px 2px 0 #000;
  letter-spacing: 3px;
}

.uipanel {
  position: fixed;
  display: flex;
  flex-direction: row;
  bottom: 0;
  z-index: 999;
  margin: 10px;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.uipanel > * {
  margin: 10px;
  width: 80px;
  border: 2px solid black;
  background: white;
  font-family: 'Fiery Turk';
  font-size: 12px;
  height: 40px;
  cursor: pointer;
  font-smooth: never;
  -webkit-font-smoothing : none;
  transition: all 0.1s;
}


.admin-panel {
  bottom: 220px;
}

.uipanel > *:hover {
  background: black;
  color: white;
  transform: scale(1.1);
}

.widthButton {
  cursor: pointer;
}

.doButton {
  border: none;
  background: black !important;
  cursor: pointer;
}

.doButton > img {
  height: 100%;
}

.canvasHolder {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
}

input[type="color"] {
  padding: 0;
  -webkit-appearance: none;
}

input[type="color"]::-webkit-color-swatch-wrapper {
	padding: 0;
}
input[type="color"]::-webkit-color-swatch {
	border: none;
}

input[type="number"] {
  padding: 0 10px;
  height: 40px;
  box-sizing: border-box;
}

input[type="number"]:hover {
  transform: scale(1);
  background: white;
  color: black;
}

#p5_loading {
  display: none !important;
}

.opacityRange {
  border: none !important;
  height: 2px;
  background: white !important;
  transform: scale(1) !important;
}


.red_banner {
  color: red;
}