

.RoomsPage {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    box-sizing: border-box;
    justify-content: center;
    z-index: 2;
    position: fixed;
    width: 100%;
    cursor: default;
    background: white;
}

.RoomsBackground {
    padding: 30px 30px;
    background: white;
    z-index: 3;
    cursor: default;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.RoomsBackground > h2 {
    font-weight: 400;
    font-size: 32px;
    margin: 0 0 20px 0;
}

.RoomsBackground > button {
    font-size: 14px;
    font-family: 'Fiery Turk';
    padding: 10px 15px;
    background: white;
    border: 2px solid black;
    font-smooth: never;
    -webkit-font-smoothing : none;
    cursor: pointer;
    transition: all 0.1s;
}

.RoomsBackground > button:hover {
    background: black;
    color: white;
    transform: scale(1.1);
}