

.LoaderHolder {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    box-sizing: border-box;
    justify-content: center;
    z-index: 2;
    position: fixed;
    width: 100%;
    cursor: none;
}


.LoaderBar {
    width: 200px;
    height: 4px;
    background: rgba(0, 0, 0, 0.2);
}

.Loader {
    background: black;
    height: 100%;
}